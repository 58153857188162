import { addRxPlugin, isRxDocument } from "rxdb";
// import {
//     RAFViewSchema
// } from './RAFSchemas/RAFViewSchema';

//import { replicateCouchDB } from 'rxdb/plugins/replication-couchdb';


import { Guid, isNotNullAndUndefined, isNullOrUndefined, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";

import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { QueryParams, UpdateRecordInfo } from "..";
import * as RAFDatabase from "../index";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { ProcessDataRow } from "../../RAFMaster/RMModules/ProcessLibrary/ProcessDataRow";
addRxPlugin(RxDBUpdatePlugin);

export const createProcessTable = async (db) => {
    //const db = await RAFDatabase.get();

    const moduleName = RAFEntityName.ProcessData;
    if (!db[moduleName]) {
        const objSchema = {
            title: moduleName,
            description: 'describes ' + moduleName,
            version: 0,
            primaryKey: 'UID',
            type: 'object',
            properties: {
                UID: { type: 'string', maxLength: 100 },// <- the primary key must have set maxLength
                //CategoryType: { type: 'string' },
                // ObjectUID: { type: 'string' },
                // ObjectName: { type: 'string' },
                // EntityName: { type: 'string' },
                // UserUID: { type: 'string' },
                // UserName: { type: 'string' },
                // Action: { type: 'string' },
                // Message: { type: 'string' },
                // PostFeed: { type: 'boolean' },
                // SecondaryRelatedTo: { type: 'string' },
                // SecondaryRelatedToType: { type: 'string' },
                // SecondaryRelatedToUID: { type: 'string' },
                // CommentType: { type: 'string' },
                // Subject: { type: 'string' },
                // Visibility: { type: 'string' },
                // DisplayOrder: { type: 'number' },
                // HasMentions: { type: 'boolean' },
                // Pinned: { type: 'boolean' },
                // ColourCode: { type: 'string' },
                // Parent: { type: 'string' },
                // ParentUID: { type: 'string' },
                // ReadCount: { type: 'number' },
                // ReactionCount: { type: 'number' },
                // ChildCount: { type: 'number' },
                // Status: { type: 'string' },
                // Priority: { type: 'string' },
                // Category: { type: 'string' },
                IsDeleted: { type: 'boolean' },
                UpdatedAt: {
                    type: 'number',
                    minimum: 0,
                    maximum: 1000000000000000,
                    multipleOf: 1
                }
            },
            indexes: [
                'UpdatedAt'
            ],
            required: [
                'UID', 'UpdatedAt'
            ],
            attachments: {
                encrypted: false
            },
            additionalProperties: true,
            ignoreDuplicate: true
        };
        try {
            await db.addCollections({
                [moduleName]: {
                    schema: objSchema,
                }
            });

            db[moduleName].preSave(function (docData) {
                docData.UpdatedAt = new Date().getTime();
                //docData.LastInspectionDate = new Date();
            });
        }
        catch (ex) {
            console.log('createTableByModuleName error', ex);
        }
    }
    //this.replicateFirestoreForAttribute(db);
    return db;
};


export const getAllProcessData = () => {
    return new Promise<any>(async (resolve, reject) => {
        const moduleName = RAFEntityName.ProcessData;
        let db = await RAFDatabase.get();
        db = await createProcessTable(db);
        //console.log('getAllDataByModuleName 1', moduleName);
        const objData = await db[moduleName].find(
            {
                selector: {
                    IsDeleted: {
                        $eq: false
                    }
                }
            }
        ).exec();

        let allData = [];
        objData && objData.map(item => {
            if (isNotNullAndUndefined(item)) {
                allData.push({ ...item.toJSON(), IsOffline: true });
            }
        });
        //console.log('getAllDataByModuleName 2', allData);

        resolve(allData);
    });
};

export const retrieveProcessObjectByUID = (UID?: string, queryParams?: QueryParams) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const moduleName = RAFEntityName.ProcessData;
            let db = await RAFDatabase.get();
            db = await createProcessTable(db);
            ////console.log('retrieveObjectByUID 1', moduleName);

            const rafObject = await db[moduleName].findOne(
                {
                    selector: {
                        ...(queryParams || {}),
                        UID: UID
                    }
                }
            ).exec();
            //console.log('retrieveObjectByUID 2', rafObject);
            const is = isRxDocument(rafObject);
            if (is) {
                resolve(rafObject.toJSON());
            }
            else {
                resolve(null);
            }
        }
        catch (ex) {
            console.log('retrieveObjectByUID exception', ex);
            resolve(null);
        }
    });
};

// export const retrieveProcessObjectByUIDAndType = (UID?: string, categoryType?: string) => {
//     return new Promise<any>(async (resolve, reject) => {
//         try {
//             debugger;
//             const moduleName = RAFEntityName.ProcessData;
//             let db = await RAFDatabase.get();
//             db = await createProcessTable(db);
//             ////console.log('retrieveObjectByUID 1', moduleName);
//             const rafObject = await db[moduleName].findOne(
//                 {
//                     selector: {
//                         //CategoryType: categoryType,
//                         UID: UID
//                     }
//                 }
//             ).exec();
//             //console.log('retrieveObjectByUID 2', rafObject);
//             const is = isRxDocument(rafObject);
//             if (is) {
//                 resolve(rafObject.toJSON());
//             }
//             else {
//                 resolve(null);
//             }
//         }
//         catch (ex) {
//             console.log('retrieveObjectByUID exception', ex);
//             resolve(null);
//         }
//     });
// };

export const saveProcessData = (rafObject?: any) => {
    return new Promise<any>(async (resolve, reject) => {
        const moduleName = RAFEntityName.ProcessData;
        let db = await RAFDatabase.get();
        db = await createProcessTable(db);
        //console.log('saveDataByModuleName 1', moduleName, rafObject);
        if (IsNullOrWhiteSpace(rafObject.UID) || isNullOrUndefined(rafObject.UID)) {
            rafObject = await insertProcessDataByModuleName(rafObject);
            resolve(rafObject);
        }
        else {
            try {
                //check document exist and insert or update

                const rafObject1 = await db[moduleName].findOne(
                    {
                        selector: {
                            UID: rafObject.UID
                        }
                    }
                ).exec();
                //console.log('saveDataByModuleName 2', rafObject1);
                const is = isRxDocument(rafObject1);
                //console.log('saveDataByModuleName 3', is);
                if (is) {
                    //update
                    //rafObject = await updateRxDocumentData(rafObject1, rafObject);

                    db[moduleName].incrementalUpsert(rafObject);

                    // await rafObject1.remove();
                    // await db[moduleName].cleanup(0);

                    // rafObject.IsOffline = true;
                    // rafObject.Synced = false;
                    // const newDocument = await db[moduleName].insert(rafObject);

                    resolve(rafObject);

                }
                else {

                    //insert
                    rafObject = await insertProcessDataByModuleName(rafObject);
                    resolve(rafObject);
                }

            }
            catch (ex) {
                console.log('saveProcessData exception', ex);
                resolve(rafObject);
            }
        }
    });
};

const insertProcessDataByModuleName = (rafObject?: any) => {
    return new Promise<any>(async (resolve, reject) => {
        const moduleName = RAFEntityName.ProcessData;
        let db = await RAFDatabase.get();
        db = await createProcessTable(db);
        //console.log('insertDataByModuleName 1', moduleName);

        rafObject = UpdateRecordInfo('Added', rafObject);
        //console.log('insertDataByModuleName 2', rafObject);
        if (IsNullOrWhiteSpace(rafObject.UID) || isNullOrUndefined(rafObject.UID)) {
            rafObject.UID = Guid.newGuid();
        }
        if (isNullOrUndefined(rafObject.IsDeleted)) {
            rafObject.IsDeleted = false;
        }
        rafObject.IsOffline = true;
        rafObject.Synced = false;
        const newDocument = await db[moduleName].insert(rafObject);

        resolve(rafObject);
    });
};

const updateRxDocumentData = (rxDocument?: any, rafObject?: ProcessDataRow) => {
    return new Promise<any>(async (resolve, reject) => {
        //console.log('updateRxDocumentData 1', rxDocument, rafObject);
        try {
            console.log('updateRxDocumentData rafObject', rafObject);
            //rafObject = UpdateRecordInfo('Added', rafObject);
            await rxDocument.update({
                $set: rafObject
            });

            // await rxDocument.update({
            //     $set: {
            //         ObjectUID: rafObject.ObjectUID,
            //         ObjectName: rafObject.ObjectName,
            //         EntityName: rafObject.EntityName,
            //         UserUID: rafObject.UserUID,
            //         UserName: rafObject.UserName,
            //         Action: rafObject.Action,
            //         Message: rafObject.Message,
            //         PostFeed: rafObject.PostFeed,
            //         SecondaryRelatedTo: rafObject.SecondaryRelatedTo,
            //         SecondaryRelatedToType: rafObject.SecondaryRelatedToType,
            //         SecondaryRelatedToUID: rafObject.SecondaryRelatedToUID,
            //         CommentType: rafObject.CommentType,
            //         Subject: rafObject.Subject,
            //         Visibility: rafObject.Visibility,
            //         DisplayOrder: rafObject.DisplayOrder,
            //         HasMentions: rafObject.HasMentions,
            //         Pinned: rafObject.Pinned,
            //         ColourCode: rafObject.ColourCode,
            //         Parent: rafObject.Parent,
            //         ParentUID: rafObject.ParentUID,
            //         ReadCount: rafObject.ReadCount,
            //         ReactionCount: rafObject.ReactionCount,
            //         ChildCount: rafObject.ChildCount,
            //         Status: rafObject.Status,
            //         Priority: rafObject.Priority,
            //         Category: rafObject.Category,
            //     }
            // });


            // await rxDocument.incrementalModify(docData => {
            //     docData.Message = rafObject.Message;
            //     return docData;
            // });
            resolve(rafObject);
        }
        catch (ex) {
            console.log('updateRxDocumentData exception', ex);
            resolve(null);
        }
    });
};


export const deleteProcessDataByUID = (UID?: string, cleanup: boolean = false) => {
    return new Promise<boolean>(async (resolve, reject) => {
        const moduleName = RAFEntityName.ProcessData;
        const db = await RAFDatabase.get();
        try {
            //console.log('deleteDataByUIDModuleName 1', moduleName, UID);
            const rafObject = await db[moduleName].findOne(
                {
                    selector: {
                        UID: UID
                    }
                }
            ).exec();

            const isDocument = isRxDocument(rafObject);
            let removedBPStep = false;
            if (isDocument) {
                removedBPStep = await rafObject.remove();
                if (cleanup === true) {
                    await db[moduleName].cleanup(0);
                }
                resolve(removedBPStep);
            }
            else {
                resolve(removedBPStep);
            }
        }
        catch (error) {
            //console.log('deleteDataByUIDModuleName error', error);
            resolve(false);
        }
    });
};